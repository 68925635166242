<template>
  <v-app-bar
    id="default-app-bar"
    app
    class="v-bar--underline"
    :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl"
    height="48"
    flat
    color="grey1"
    fixed
  >
    <default-drawer-toggle />

    <v-toolbar-title class="font-weight-bold text-h5 white--text">
      FIAP風險導向系統</v-toolbar-title
    >

    <v-spacer />
  </v-app-bar>
</template>

<script>
// Utilities
import { get, sync } from "vuex-pathify";

export default {
  name: "DefaultBar",

  components: {
    DefaultDrawerToggle: () =>
      import(
        /* webpackChunkName: "default-drawer-toggle" */
        "./drawer/DrawerToggle"
      ),
  },

  computed: {
    ...sync("app", ["drawer", "mini"]),
    name: get("route/name"),
  },
};
</script>
